import React from "react";
import headerlogo from "../src/image/logo-img-header.png";
import dropdownicon from "../src/image/dropdown-icon-header.png";
import languageimg from "../src/image/dropdown-select-img.png";
import Background  from '../src/image/dropdown-select-img.png';



function Nb(){

    var englishimg = {
        width: "100%",
        height: "400px",
        backgroundImage: "url(" + { Background } + ")"
      };
    return(

        <>

    <nav>
        <input type="checkbox" id="nav-toggle"/>
        <div className="logo">
            <img src={headerlogo} class="header-logo-img"/>
        </div>
       <ul className="links">
           <li className="link-name">
             <img src={dropdownicon}/>
                <div className="select-wrapper">
                <select name="option" id="cars">
                <option class="en" value="en"  
	                 style={ englishimg }>English</option> 
                  <option value="saab" className="select-name">
                    <span><img src={languageimg}/></span>
                    <span><p  className="select-name">German</p></span>
                </option>
                  <option value="mercedes">french</option>
                  <option value="audi" className="select-name">Spanish</option>
                </select>
                </div>
            </li>
           <li className="link-name"><a href="">Log In</a></li>
           <li className="link-button"><a href="" className="button-name">Sign Up</a></li>
       </ul>
       <label for="nav-toggle" class="icon-burger">
           <div className="line"></div>
           <div className="line"></div>
           <div className="line"></div>
       </label>
    </nav>
    <label for="nav-toggle" class="icon-burger">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
    </label>
        
        
        </>
    )
}



export default Nb;