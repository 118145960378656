import React from "react";
import footerlogo from "../src/image/logo-img-header.png";
import backtop from "../src/image/top-back-img.svg";



function Footer(){
    return(
       <>
       
       <div className="Footer-sec">
         <div className="container">
            <div className="Footer-uper">
                <div className="footer-upper-left">
                    <div className="footer-upper-left-inner">
                         <div className="footer-logo">
                            <img src={footerlogo} class="footer-logo-img"/>
                         </div>

                         <div className="footer-links">
                            <ul className="footer-link-inner">
                                <li className="footer-name">
                                    <a href="" className="link-name">Terms & Conditions</a>
                                </li>

                                <li className="footer-name">
                                    <a href="" className="link-name">Account</a>
                                </li>

                                <li className="footer-name">
                                    <a href="" className="link-name">Customer Support</a>
                                </li>

                                <li className="footer-name">
                                    <a href="" className="link-name">Privacy</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className="footer-upper-right">
                   <p className="start-desc">Ready to get started?</p>
                   <a href="" className=" white-button">try For free</a>

                </div>

            </div>

            <div className="Footer-lower">
                <div className="copyright">
                  <h3 className="copyright-desc">Copyright © 2023. All rights reserved.</h3> 
                </div>

                <div className="back-top">
                   <p className="top-desc">Back To Top</p>
                   <img src={backtop}  onClick={() => {
                  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                   }}  />
                </div>
                

            </div>
        </div>
       </div>
       
       
       
       
       </>
    )
}


export default Footer;