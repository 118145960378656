import React, { useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import music from './image/music-img.png';
import game from './image/game-img.png';
import book from './image/book-img.png';


function Caraousel() {


    // responsive-slider-function


    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1920 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1920, min: 1199 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1199, min: 767 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 767, min: 575 },
            items: 3
        },
       minmobile: {
            breakpoint: { max: 575, min: 340 },
            items: 2
        }
    };

 


    // background-change-function


  


  
    return (
        <>


            <div className="banner-sec">
                <div className="row" >
                    <div className="banner-left">
                        <h6 className="small-heading">The one place to be to</h6>
                        <h3 className="banner-discover-desc">Discover the finest </h3>
                        <h1 className="banner-heading">Entertainm<span className="ent-dsec">ent</span></h1>

                        <p className="banner-title">Upgrade your streaming experience on all your devices!<br></br> Become a member of Primescreen.net services today.</p>

                        <a href="" className="white-button ">Get Started</a>
                    </div>

                    <div className="banner-right">
                        <Carousel 
                           ssr={true}
                           infinite={true}
                           autoPlay={true}
                           autoPlaySpeed={1000}
                           autoplayTimeout = {1000}
                           responsive={responsive}>
                            <div className='carousel-img'>
                                <img src={music} width="100%" />
                            </div>
                            <div className='carousel-img'>
                                <img src={game} width="100%" />
                            </div>
                            <div className='carousel-img'>
                                <img src={book} width="100%" />
                            </div>
                            <div className='carousel-img'>
                                <img src={music} width="100%" />
                            </div>
                            <div className='carousel-img'>
                                 <img src={game} width="100%" />
                            </div>
                            <div className='carousel-img'>
                                <img src={book} width="100%" />
                            </div>
                            <div className='carousel-img'>
                                <img src={music} width="100%" />
                            </div>
                            <div className='carousel-img'>
                                <img src={game} width="100%" />
                            </div>
                            <div className='carousel-img'>
                                <img src={book} width="100%" />
                            </div>

                        </Carousel>

                    </div>
                </div>
            </div>








     
        </>
    )
}

export default Caraousel;