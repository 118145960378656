import './App.css';
import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';
import Home from './Home';
import Nb from './Nb';
import Footer from './Footer';

function App() {
  return (
   <>
   
   <Router>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/Nb' element={<Nb/>}/>
      <Route path='/Footer' element={<Footer/>}/>




    </Routes>
   </Router>

   </>
  );
}

export default App;
